import { useI18next } from 'gatsby-plugin-react-i18next';
import React, { FC, ReactNode, useState } from 'react';
import { MultiCardSliderCardInterface } from '../../../interfaces/multiCardSliderCard';
import { linkHelper } from '../../../utils/linkHelper';
import {
    Card,
    CardImage,
    CardLink,
    CardLinkWrapper,
    CardSubtitle,
    CardTitle,
    LeftArrow,
    LeftArrowWrapper,
    RightArrow,
    RightArrowWrapper,
    Slider,
    Text,
} from './MultiCardSilderStyle';

interface MultiCardSliderInterface {
    cards: Array<MultiCardSliderCardInterface>;
    sliderHeight?: number;
}
// if you need, you can easy change sliderHeight in your component, just pass props sliderHeight with your value.
const MultiCardSlider: FC<MultiCardSliderInterface> = ({ cards, sliderHeight = 456 }) => {
    const { language } = useI18next();
    const numberOfCards = cards.length;
    const [lastActiveCardDesktop, setLastActiveCardDesktop] = useState(2);
    const [lastActiveCardTablet, setLastActiveCardTablet] = useState(1);
    const [lastActiveCardMobile, setLastActiveCardMobile] = useState(0);

    const nextImage = (): void => {
        lastActiveCardDesktop < numberOfCards - 1
            ? setLastActiveCardDesktop((lastActiveCardDesktop) => ++lastActiveCardDesktop)
            : setLastActiveCardDesktop(2);
        lastActiveCardTablet < numberOfCards - 1
            ? setLastActiveCardTablet((lastActiveCardTablet) => ++lastActiveCardTablet)
            : setLastActiveCardTablet(1);
        lastActiveCardMobile < numberOfCards - 1
            ? setLastActiveCardMobile((lastActiveCardMobile) => ++lastActiveCardMobile)
            : setLastActiveCardMobile(0);
    };

    const previousImage = (): void => {
        lastActiveCardDesktop === 2
            ? setLastActiveCardDesktop(numberOfCards - 1)
            : setLastActiveCardDesktop((lastActiveCardDesktop) => --lastActiveCardDesktop);
        lastActiveCardTablet === 1
            ? setLastActiveCardTablet(numberOfCards - 1)
            : setLastActiveCardTablet((lastActiveCardTablet) => --lastActiveCardTablet);
        lastActiveCardMobile === 0
            ? setLastActiveCardMobile(numberOfCards - 1)
            : setLastActiveCardMobile((lastActiveCardMobile) => --lastActiveCardMobile);
    };

    const displayCards = (): ReactNode =>
        cards.map((card: MultiCardSliderCardInterface, index: number) => (
            <Card
                key={card.title + index}
                position={index}
                lastActiveDesktop={lastActiveCardDesktop}
                lastActiveTablet={lastActiveCardTablet}
                lastActiveMobile={lastActiveCardMobile}
            >
                <CardTitle>{card.title}</CardTitle>
                <CardImage src={card.image} alt={card.title} />
                <CardSubtitle>{card.subTitle}</CardSubtitle>
                <Text dangerouslySetInnerHTML={{ __html: card.description }} />
                <CardLinkWrapper>
                    <CardLink to={linkHelper(card.link, language)}>{card.linkText}</CardLink>
                </CardLinkWrapper>
            </Card>
        ));

    return (
        <Slider height={sliderHeight}>
            {displayCards()}
            <LeftArrowWrapper onClick={previousImage} numberOfCards={numberOfCards}>
                <LeftArrow />
            </LeftArrowWrapper>
            <RightArrowWrapper onClick={nextImage} numberOfCards={numberOfCards}>
                <RightArrow />
            </RightArrowWrapper>
        </Slider>
    );
};

export default MultiCardSlider;
